import React from 'react';
import logo from '../logo.png';

export default function Agreement({ isLoaded, userData }) {
  if(!isLoaded) return (<p>загрузка...</p>);

  return (<div style={{ padding: 12 }}>
    <center>
      <img src={logo} style={{ width: 300, height: 'auto' }}/><br/>
      <b>КРЕДИТНЫЙ ДОГОВОР №{userData.timestamp - 1600000000}<br/>о предоставлении кредита физическому лицу</b>
    </center>
  
    <p align="center"><b>{ (new Date(userData.timestamp*1000)).toLocaleDateString('ru-RU') }</b></p>
  
    <p>Акционерный Коммерческий Банк NRW Bank, именуемый в дальнейшем «Банк», в лице управляющего филиалом в лице управляющего филиалом Михаэль Штёльтинг и {userData.username}. Номер паспорта <b>{userData.passport}</b>, код подразделения {userData.code}, выдан {userData.date}, дата рождения {userData.birthday} «Заемщик», с другой стороны, вместе именуемые «Стороны», заключили настоящий Договор о нижеследующем</p>
  
  
  <h3>1. ПРЕДМЕТ ДОГОВОРА</h3>
  
  <p>1.1 Банк обязуется предоставить Заемщику кредит в сумме <b>{userData.summ} рублей</b>, на срок <b>{userData.term}</b> месяцев, включительно с уплатой процентов за пользование кредитом по годовой ставке 10.6% на потребительские нужды. А заемщик обязуется возвратить Банку полученный Кредит <b>{ (new Date(userData.timestamp*1000 + userData.term*31*86400*1000)).toLocaleDateString('ru-RU').split('.').splice(0,3).join('.') }</b> года</p>
  
  <h3>2. УСЛОВИЯ РАСЧЁТОВ И ПЛАТЕЖЕЙ</h3>
  
  <p>2.1 Датой выдачи Кредита является дата образования ссудной задолженности. </p>
  
  <p>Датой погашения задолженности по Кредиту и уплаты процентов, неустоек считается дата поступления средств в кассу Банка или зачисления средств на счет Банка.</p>
  
  <p>2.2 Процентный период составляет 1 (Один) календарный месяц.</p>
  
  <p>2.3 При исчислении процентов, неустоек в расчет принимается фактическое количество календарных дней в платежном периоде, а в году – действительное число календарных дней 365.</p>
  
  <p>2.4 Заемщик предоставляет право Банку при наличии задолженности по Кредиту и/или процентам за пользование Кредитом списывать денежные средства, поступающие на текущий счет Заемщика, по мере их поступления в безакцептном порядке на погашение данной задолженности. Безакцептное списание в счет погашения задолженности по Кредиту осуществляется независимо от истечения предельного срока пользования Кредитом, установленного в п. 1.1. настоящего Договора, и независимо от срока уплаты процентов, установленного в п. 2.4. настоящего Договора.</p>
  
  <p>2.5 В дату погашения задолженности по Кредиту в порядке, определенном п. 2.2. настоящего Договора, Клиент предоставляет право Банку безакцептно списывать со своего текущего счета средства в уплату процентов, начисленных за пользование соответствующей суммой, в том числе процентов, определяемых п. 8.2. настоящего Договора.</p>
  
  <p>2.6 При отсутствии денежных средств на текущем счете Заемщика, достаточных для погашения задолженности по Кредиту и уплаты процентов на дату погашения Кредита, Заемщик обязан самостоятельно погасить имеющуюся задолженность по Кредиту и уплатить начисленные проценты.</p>
  
  <p>2.7 Суммы, вносимые Заемщиком (списываемые с текущего счета Банком) в счет погашения задолженности по настоящему Договору, направляются, вне зависимости от назначения платежа, указанного в платежном документе, в следующей очередности: </p>
  
  <ul>
    <li>погашение издержек Банка по получению исполнения настоящего Договора; </li>
    <li>уплата неустоек; </li>
    <li>уплата просроченных процентов; </li>
    <li>уплата срочных процентов; </li>
    <li>погашение просроченной задолженности по Кредиту; </li>
    < li>погашение срочной задолженности по Кредиту.</li>
  </ul>
  <p>2.8 Обязательства Заемщика считаются надлежаще и полностью выполненными после возврата Банку всей суммы Кредита, уплаты процентов за пользование Кредитом, неустоек в соответствии с условиями настоящего Договора, определяемых на дату погашения Кредита, и возмещения расходов, связанных с взысканием задолженности.</p>
  
  
  <h3>3. ОБЯЗАТЕЛЬСТВА И ПРАВА БАНКА</h3>
  
  <p>3.1 Банк обязан:</p>
  
  <p>3.1.1 произвести выдачу (зачисление, перечисление) Кредита в соответствии с процентами п. 2.2. настоящего Договора ;</p>
  
  <p>3.2 Банк вправе: </p>
  
  <p>3.2.1 проверить платежеспособность Заемщика, его финансовое положение, контролировать выполнение Заемщиком принятых на себя обязательств по Кредиту в течение срока действия настоящего Договора.</p>
  
  
  <h3>4. ОБЯЗАТЕЛЬСТВА И ПРАВА ЗАЕМЩИКА</h3>
  
  <p>4.1 Заемщик обязан:</p>
  
  <p>4.2 своевременно пополнять денежные средства On-Line переводом в платёжной системе Банка NRW Bank System</p>
  
  <p>4.3 обеспечить возврат Кредита на условиях настоящего Договора;</p>
  
  <p>4.4 не передавать информацию о настоящем Договоре и всех возникающих в связи с его исполнением обязательств третьим лицам без согласия Банка;</p>
  
  <p>4.5 получить Кредит в день заключения настоящего Договора;</p>
  
  <p>4.6 информировать Банк об изменении места жительства и/или состава семьи, и/или работы, и/или фамилии и других обстоятельств в течение 10 (Десяти) календарных дней с момента наступления события.</p>
  
  
  <h3>5. ЗАЕМЩИК ВПРАВЕ:</h3>
  
  <p>5.1 произвести досрочный возврат Кредита и процентов по Кредиту полностью или частично в дату очередного платежа, письменно уведомив Банк за 2 (Два) рабочих дня до предполагаемой даты платежа.</p>
  
  <p>5.2 Заемщик отвечает по своим обязательствам в соответствии с настоящим Договором всем своим имуществом в пределах задолженности по Кредиту, процентам, неустойкам и иным платежам по Договору.</p>
  
  <p>5.3 Заемщик вправе отказаться от кредита в течении 24 часов с момента подписания кредитного договора. Для отказа кредита, нужно обратиться в службу поддержки банка и выполнить все требования.</p>
  
  
  <h3>6. СРОК ДОГОВОРА</h3>
  
  <p>6.1 Договор вступает в силу с момента его подписания и получения кредита со стороны клиента. Сторонами и действует по момент полного выполнения Заемщиком своих обязательств по настоящему Договору.</p>
  
  
  <h3>7. ОСНОВАНИЯ И ПОРЯДОК РАСТОРЖЕНИЯ ДОГОВОРА</h3>
  
  <p>7.1 Банк имеет право в судебном порядке расторгнуть настоящий Договор и потребовать возврата всей суммы Кредита, процентов по Кредиту и иных платежей, предусмотренных настоящим Договором при наступлении одного или нескольких следующих условий:</p>
  
  <p><p>7.1.1 неисполнения или ненадлежащего исполнения Заемщиком его обязательств по настоящему Договору по возврату Кредита и уплате процентов по Кредиту;</p>
  
  <p>7.1.2 ухудшения финансового положения Заемщика;</p>
  
  <p>7.1.3 использования Кредита не по целевому назначению, установленному в п. 1.1.</p></p>
  
  
  <h3>8. ОТВЕТСТВЕННОСТЬ</h3>
  
  <p>8.1 За неисполнение или ненадлежащее исполнение обязательств по настоящему Договору Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации.</p>
  
  <p>8.2 В случае несвоевременного внесения (перечисления) Кредита и/или процентов по Кредиту, допускается просрочка платежа сроком в 14 календарных дней.</p>
  
  
  <h3>9. УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ КРЕДИТА</h3>
  
  <p>9.1 Банк открывает Заемщику счет в платёжной системе Банка NRW Bank System</p>
  
  <p>9.2 Выдача Кредита производится единовременно путем зачисления денежных средств на счет Заёмщика в платежной системе банка путем прохождения идентификации личности.</p>
  
  <p>9.3 Погашение Кредита осуществляется ежемесячно равными долями, начиная с { (new Date(userData.timestamp*1000+2678400000)).toLocaleDateString('ru-RU') } года.</p>
  
  <p>9.4 Кредит по праву является не погашенным, если банк осуществил перевод на платежную систему и заемщик не осуществлял вывод денежных средств</p>
  
  <p>9.5 В случае не вывода средств заёмщиком со счёта, кредит не будет считаться погашенным.</p>
  
  <p>9.6 Если спустя 24 часа, заемщик не вывел кредитованные деньги , то аккаунт заёмщика становится заблокированным. Для разблокировки аккаунта потребуется оплата штрафа в тройном размере месячной задолженности по кредиту.</p>
  
  <p>9.7 Прочие условия</p>
  
  <p>9.7.1 Изменения и дополнения к настоящему Договору действительны, если они совершены в письменной форме и подписаны уполномоченными представителями Сторон.</p>
  
  <p>9.7.2 Все возникающие в процессе исполнения настоящего договора Споры разрешаются Сторонами путем переговоров, а при не достижении согласия – в порядке, установленном действующим договором.</p>
  
  <p>9.7.3 Во всем остальном, не предусмотренном настоящим Договором, Стороны будут руководствоваться действующим законодательством РФ.</p>
  
  <p>9.7.4 В случае изменения наименования, адресов, платежных и иных реквизитов Стороны уведомляют о совершенных изменениях в течение 20 (Двадцать) календарных дней с даты произошедших изменений.</p>
  
  <p>9.7.5 Договор составлен в двух экземплярах, имеющих равную юридическую силу, по одному для каждой из Сторон.</p>
  
  <h3>10. ПОДПИСИ СТОРОН</h3>
  </div>);
}