import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { TextField, Grid, Button, Typography } from '@material-ui/core';

export default function AdminPanel() {
  const [name, setName] = useState('');
  const [passport, setPassport] = useState('');
  const [code, setCode] = useState('');
  const [date, setDate] = useState('');
  const [summ, setSumm] = useState('');
  const [term, setTerm] = useState('');
  const [birthday, setBirthday] = useState('');

  const [isLoaded, setIsLoaded] = useState(true);
  const [error, setError] = useState(null);

  const [resultURL, setResultURL] = useState(null);

  const API_URL = `${window.location.protocol}//${window.location.host}/`;
  
  const add = () => {
    fetch(API_URL + 'api.php', generateRequest({
      name,
      passport,
      code,
      date,
      summ,
      term,
      birthday,
      method: 'addAgreement', 
    }))
      .then(res => res.json())
      .then(
        (result) => {
          const url = API_URL + 'c/' + result.url;
          setIsLoaded(true);
          setResultURL(url);
          navigator.clipboard.writeText(url)
          setName('');
          setPassport('');
          setCode('');
          setDate('');
          setSumm('');
          setTerm('');
          setBirthday('');
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  };

  const check = () => {
    fetch(API_URL + 'api.php', generateRequest({
      method: 'checkThisShit', 
    }))
      .then(res => res.json())
      .then(
        (result) => {
          window.alert(result.status);
        }
      )
  };

  return <Grid container>
    <Grid item xs={12}>
      <Typography variant="h5">Создание договора</Typography>
    </Grid>
    {!resultURL && <Grid item xs={4}>
      <TextField label="ФИО" fullWidth onChange={e => setName(e.target.value)} value={name}/><br/>
      <InputMask mask="9999 999999"  value={passport} onChange={e => setPassport(e.target.value)}>
        { inputProps => <TextField {...inputProps} placeholder="____ ______" label="Номер и серия паспорта" fullWidth /> }
      </InputMask><br/>
      <InputMask mask="999-999"  value={code} onChange={e => setCode(e.target.value)}>
        { inputProps => <TextField {...inputProps} placeholder="___-___" label="Код подразделения" fullWidth />}
      </InputMask><br/>
      <InputMask mask="99.99.9999"  value={code} onChange={e => setDate(e.target.value)} value={date} >
        {inputProps => <TextField {...inputProps} placeholder="__.__.__" label="Когда выдан" fullWidth/>}
      </InputMask><br/>
      <InputMask mask="99.99.9999"  value={code} onChange={e => setBirthday(e.target.value)} value={birthday} >
        {inputProps => <TextField {...inputProps} placeholder="__.__.__" label="Дата рождения" fullWidth/>}
      </InputMask><br/>
      <TextField label="Размер кредита" fullWidth onChange={e => setSumm(e.target.value)} value={summ} /><br/>
      <TextField label="Срок кредита (мес.)" fullWidth onChange={e => setTerm(e.target.value) } value={term} /><br/>
      <Button variant="outlined" style={{ marginTop: 12}} fullWidth onClick={add}>Создать</Button>
      <hr/>
      <Button variant="outlined" style={{ marginTop: 12}} fullWidth onClick={check}>Проверить состояние сервера</Button>
    </Grid>}
    {resultURL && <>
      <p>Готово. Ссылка: {resultURL}</p><br/>
      <p>Ссылка была автоматически скопирована в буффер обмена. Используй Ctrl + V для вставки</p>
      <Button variant="outlined" style={{ marginTop: 12}} fullWidth onClick={() => setResultURL(null)}>Создать еще одну</Button>
    </>}
  </Grid>;
}

function generateRequest(params) {
  return {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json' 
    },
    body: JSON.stringify(params)
  };
}